// import draggable from 'vuedraggable'
import request from '@/utils/request.js'

export default {
	components: {
		// draggable,
		request
	},
	data() {
		return {
			FootNavMenuData: [],
		}
	},
	created() {

	},
	methods: {
		async postdata(url, data) {
			if (url.includes('/api')) {
				url = url.replace('/api', '')
			}
			let res = await request({
				url: url,
				method: "POST",
				data: data
			})
			return res
		},
		async postdel(url, data) {
			let res = await request({
				url: url,
				method: "POST",
				data: {
					ids: data
				}
			})
			return res
		},
		getUrl: function(url) {
			if (url && url != '' && url.indexOf('http') < 0) {
				url = this.$target + url
			}
			return url
		},
		/********时间格式化处理********/
		//使用事例 return top.dateFtt(crtTime,"yyyy/MM/dd hh:mm:ss");
		dateFtt(date, fmt) {
			date = new Date(date);
			var o = {
				"M+": date.getMonth() + 1, //月份   
				"d+": date.getDate(), //日   
				"h+": date.getHours(), //小时   
				"m+": date.getMinutes(), //分   
				"s+": date.getSeconds(), //秒   
				"q+": Math.floor((date.getMonth() + 3) / 3), //季度   
				"S": date.getMilliseconds() //毫秒   
			};
			if (/(y+)/.test(fmt))
				fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
			for (var k in o)
				if (new RegExp("(" + k + ")").test(fmt))
					fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k])
						.length)));
			return fmt;
		},
		//页面跳转
		toUrl(name, params) {
			//name 页面名称
			//params 参数
			//var params = {
			// 	userId: userId,
			// 	type: type
			// }
			this.$router.push({
				name: name,
				params: params
			})
		},
	}
}