<!--
 * @Description: 项目根组件
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-04-05 13:14:48
 -->
<template>
	<div id="app" name="app">
		<el-container>
			<!-- 顶部导航栏 -->
			<div class="topbar" v-if="router == -1 ">
				<div class="nav">
					<ul style="width: 100%;display: flex;justify-content: space-between">
						<!-- <el-button type="text" @click="login">登录</el-button> -->
						<li style=";color:#666">您好，欢迎来到{{Config.SiteName}}！全球现货 · 正品保障 · 闪电发货</li>

						<li style="margin: 0;flex-shrink: 0;">
							<a href="javascript:[0]" @click="feedBackBool=true" style="margin-right: 16px;">建议&投诉</a>
							<div v-if="!this.$store.getters.getUser" style="display: inline-block;margin-right: 16px;">
								<!-- <router-link to='/login'> -->
								<el-button type="text" @click="login" style="font-size: 12px;margin-right: 16px;">请登录
								</el-button>
								<!-- </router-link> -->
								<!-- <router-link to='/register'> -->
								<el-button type="text" @click="registergo"
									style="font-size: 12px;color: #ff5a06;margin-left: 0;">免费注册</el-button>
								<!-- </router-link> -->
							</div>
							<div v-else class="temp" style="display: inline-block;margin-right: 16px;color: #666;">
								欢迎！
								<el-popover placement="top" width="180" v-model="visible">
									<p>确定退出登录吗？</p>
									<div style="text-align: right; margin: 10px 0 0">
										<el-button size="mini" type="text" @click="visible = false">取消</el-button>
										<el-button type="primary" size="mini" @click="logout">确定</el-button>
									</div>
									<el-button type="text" slot="reference" style="width: 80px;" class="text-1">{{
											this.$store.getters.getUser.UserName
									}}
									</el-button>
								</el-popover>
							</div>

							<a href="javascript:[0]" @click="goOrder" style="margin-right: 16px;">我的订单</a>
							<!-- <a href="javascript:[0]" class="dingdanwoliu" style="margin-right: 16px;">订单物流 <img
									src="./images/ewm.jpg" alt="" style="width:120px;height:120px"
									class="wuliutupian" /></a> -->

							<!-- <router-link to="/"></router-link> -->
							<!-- <span class="sep">|</span> -->


						</li>


					</ul>
				</div>
			</div>
			<!-- 顶部导航栏END -->

			<div class="feedback-alert" v-if="feedBackBool">
				<div class="content boxsiz content-1" style="top: 50%;"><span class="iconfont iconguanbi  alert-close"
						@click="feedBackClose">X</span>
					<p class="title">问题反馈</p>
					<div class="form">
						<div class="form-top clear"><img src="./images/feedback.png" alt="" class="fl">
							<div class="fl">
								<p class="from-title1">您提出的意见和建议，我们深表感谢！</p>
								<p class="from-title2">如建议被采纳，我们将给予一定的奖励</p>
							</div>
						</div>
						<div class="form-center"><textarea class="textarea boxsiz" v-model="feedBack.Contents"
								placeholder-class="formplaceholder" placeholder="请简要说明，如：
1、某个网站页面体验不佳
2、对接客服的服务不好
3、某个商品数据不准">
								</textarea>
							<div class="drop-or-down-phone line-block">留下联系方式，我们会及时跟您联系 (选填)</div>
							<div class="phone-input-div"><span class="va-m">联系方式：</span><input type="text"
									v-model="feedBack.Contact" placeholder="请输入您的联系方式" class="va-m boxsiz phone-input">
							</div>
							<div class="feedback-btn" @click="feedBackClick"><a href="javascript:void(0)"
									class=" common-btn-radius line-block">提交</a></div>
						</div>
					</div>
				</div>
			</div>

			<!-- 顶栏容器 -->
			<div v-if="router3 == 1">
				<el-header style="height: 140px;" v-if="router == -1">
					<div class="el-menu-demo width1225" style="position: relative;">
						<div class="logo clear">
							<router-link to="/">
								<!-- <img :src="getUrl(Config.SiteLogo)" alt style="width: 160px;max-height: 70px;"/> -->
								<img src="/pc_static/img/logo.png" alt="" style="width: 129px;height:77px;" />
							</router-link>
						</div>
						<div class="flexcum">

							<div class="flexspb" style="margin-top: 20px;">
								<div class="so">
									<img src="" alt="" class="search">
									<el-input placeholder="请输入搜索内容" v-model="search"
										style="height: 40px;display: flex;align-items: center;" class="flexal">
										<el-button style="height: 40px;" slot="append" @click="searchClick">搜索
										</el-button>
									</el-input>
								</div>
								<router-link to="/shoppingCart" v-if="router2 != -1">
									<div class="car flexal">
										<img src="./images/car.png" alt=""
											style="width: 20px;height: 20px;margin-right: 8px;" />
										购物车
										<div class="num flexcen">（{{ getNum }}）</div>
									</div>
								</router-link>
							</div>
							<!-- <div class="header-m clear">
								<div class="header-r-l fl">
									<ul class="command-searches clear boxsiz">
										<li class="fl ">
											<router-link to='/supplierinfo'>
												免费入驻
											</router-link>
										</li>
									</ul>
								</div>
							</div> -->
							<div class="head1" v-if="shopRou == -1">
								<div class="head1-cen">
									<ul class="head1-ul clear">
										<li class="bgcs">
											<el-link @click="NavToUrl('/')">
												首页
											</el-link>
										</li>
										<!-- 	<li class="bgcs">
											<el-link @click="NavToUrl('/goods')">
												类目检索
											</el-link>
										</li>
										<li class="bgcs">
											<el-link @click="NavToUrl('/brands')">
												品牌列表
											</el-link>
										</li> -->
										<!-- <li class="bgcs">
											<el-link @click="NavToUrl('/supplierinfo')">
												免费入驻
											</el-link>
										</li> -->
										<!-- <li class="bgcs">
										<el-link @click="NavToUrl('/')">
											加入我们
										</el-link>
									</li> -->
										<!-- <li v-for="(item, index) in NavMenuData" :key="index">
									<el-link :underline="false" @click="NavToUrl('/goods?categoryID=' + item.Id)">{{
											item.Title
									}}</el-link>
									<router-link  :to="item.linkUrl" >{{item.name}}</router-link>
								</li> -->
									</ul>
								</div>
							</div>
						</div>
					</div>
				</el-header>
			</div>
			<!-- 顶栏容器END -->

			<!-- 登录模块 -->
			<MyLogin></MyLogin>
			<!-- 注册模块 -->
			<!-- <MyRegister :register="register" @fromChild="isRegister"></MyRegister> -->

			<!-- 主要区域容器 -->
			<el-main class="elmain"
				:class="[(router == 1) ? 'pads' : '', shopRou == 1 ? 'pads bgF' : router3==0?'pads':'']">
				<keep-alive>
					<router-view v-if="isRouterAlive" :key="$route.fullPath"></router-view>
				</keep-alive>
			</el-main>
			<!-- 主要区域容器END -->

			<!-- 底栏容器 -->
			<el-footer style="background: #f5f5f5;height: 100%;">
				<!-- height: 332px; -->
				<div class="footer-ewmcode">
					<div class="foot-list-fl">
						<div class="foot-list-wrap p">

							<div>
								<ul v-for="(item, index) in bangzhulist" :key="index">
									<li class="foot-th">
										{{ item.Title }}
									</li>
									<li v-for="(itclass, classindex) in item.ChildrenList" :key="classindex">
										<router-link :to="{ path: '/helpInfo', query: { id: itclass.Id } }"
											@click.native="flushCom">
											{{ itclass.Title }}
										</router-link>
									</li>
								</ul>
							</div>
							<div>
								<ul>
									<li class="foot-th">
										网站协议
									</li>
									<li v-for="(item, index) in xieyilist" :key="index">
										<router-link :to="{ path: '/agreement', query: { id: item.Id } }"
											@click.native="flushCom">
											{{ item.Title }}
										</router-link>
									</li>
								</ul>
							</div>
							<div>
								<ul v-for="(item, index) in gywmlist" :key="index">
									<li class="foot-th">
										{{ item.Title }}
									</li>
									<li v-for="(itclass, classindex) in item.ChildrenList" :key="classindex">
										<router-link :to="{ path: '/joinus/aboutus', query: { type: itclass.type } }"
											@click.native="flushCom">
											{{ itclass.Title }}
										</router-link>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div class="right-contact-us">
						<h3 class="title">客服热线（9:00-22:00）</h3>
						<span class="phone">联系电话：{{ Config.CustomerInfo }}</span>
						<p class="tips">联系邮箱：3840193983@qq.com</p>
						<!-- <div class="qr-code-list clearfix" style="display: flex;justify-content: center;">							
							<a class="qr-code qr-code-tpshop" href="javascript:;">
								<img v-if="Config.WeChatQrCode!=''" :src="Config.WeChatQrCode" alt="" style="width:120px;height:120px" />
							</a>
						</div>
						<p class="tips">微信扫码，及时掌握元器件购买优惠</p> -->
					</div>
				</div>
				<div class="footer">
					<div class="mod_help" v-if="false">
						<p>
							<router-link to="/">首页</router-link>
							<span style="padding:0 10px">|</span>
							<span v-for="(item, index) in NavMenuData" :key="index" style="padding:0">
								<router-link :to="item.linkUrl">{{ item.name }}</router-link>
								<span style="padding:0 10px" v-if="NavMenuData.length - 1 != index">|</span>
							</span>
						</p>
					</div>
					<div class="btmInfo">
						<!-- <div>
							<span class="sep"></span>
							<a :href="$target + '/服务协议.docx'">服务协议</a>
						</div> -->
						<!--<div>
							<span class="sep">|</span>
							<a href="#">用户协议</a>
						</div>
						<div>
							<span class="sep">|</span>
							<a href="#">用户反馈</a>
						</div>-->
						<div>
							<!-- <span class="sep">|</span> -->
							<a :href="$target + '用户协议.doc'">用户协议</a>
						</div>

						<!--<div>
							<span class="sep">|</span>
							<a href="#">销售条款</a>
						</div>
						<div>
							<span class="sep">|</span>
							<a href="#">网站申明</a>
						</div>
						<div>
							<span class="sep">|</span>
							<a href="#">购物流程</a>
						</div>
						<div>
							<span class="sep">|</span>
							<a href="#">服务热线</a>
						</div>
						<div>
							<span class="sep">|</span>
							<a href="#">购物指南</a>
						</div>
						<div>
							<span class="sep">|</span>
							<a href="#">售后服务</a>
						</div> -->
						<div>
							<span class="sep">|</span>
							<a href="https://beian.miit.gov.cn">{{ Config.webcrod}}</a>
						</div>
					</div>
					<p class="coty">
						{{Config.copyright}}
					</p>
				</div>
			</el-footer>
			<!-- 底栏容器END -->
		</el-container>
	</div>
</template>

<script>
	import {
		mapActions
	} from "vuex";
	import {
		mapGetters
	} from "vuex";

	export default {
		provide() {
			return {
				reload: this.reload,
			};
		},
		beforeUpdate() {
			this.activeIndex = this.$route.path;
			this.router = this.$route.path.indexOf('login')

			if (this.router == -1) {
				this.router = this.$route.path.indexOf('register')
			}
			if (this.$route.path.indexOf('supplierinfo') == -1) {
				this.router3 = 1
			} else {
				this.router3 = 0
			}
			if (this.$route.path.indexOf('shoppingCart') == -1 || this.$route.path.indexOf('goods/details') == -1) {
				this.router2 = 1
			}
			this.shopRou = this.$route.path.indexOf('shop')
		},
		data() {
			return {
				feedBack: {
					"Contact": "",
					"Contents": ""
				},
				feedBackBool: false,
				activeIndex: "", // 头部导航栏选中的标签
				search: "", // 搜索条件
				register: false, // 是否显示注册组件
				visible: false, // 是否退出登录
				NavMenuData: [],
				Config: {
					SiteName: '',
					WebCompany: '',
					CustomerMobile: '',
					SiteLogo: '',
					CustomerInfo: '',
					webcrod: '',
					copyright: '',
					WeChatQrCode: ''
				},
				isRouterAlive: true,
				dropdown: true,
				temp: "商品",
				router: 0,
				router2: 0,
				router3: 0, //商家入驻头部开关
				shopRou: 0,
				bangzhulist: {},
				gywmlist: [{
						Title: '关于我们',
						ChildrenList: [{
								Title: '企业介绍',
								type: 0,
							},
							{
								Title: '联系我们',
								type: 1,
							},
						]
					}


				],
				xieyilist: [], // 协议列表

			}
		},
		created() {
			// 获取浏览器localStorage，判断用户是否已经登录
			if (localStorage.getItem("userToken")) {
				// 如果已经登录，设置vuex登录状态
				//JSON.parse(localStorage.getItem("user"))
				this.GetUserInfo();
			}
			// this.loadNavMenuList();
			// this.Getconfig();
			this.loadbangzhuList();
			this.AgreementList()
			this.GetConfig()
		},
		mounted() {
			var clientWidth = window.innerWidth
			if (clientWidth <= 750) {
				window.location.href = "/h5"
			}
		},
		computed: {
			...mapGetters(["getUser", "getNum"]),
		},
		watch: {
			// 获取vuex的登录状态
			getUser: async function(val) {
				if (val === "") {
					// 用户没有登录
					this.setShoppingCart([]);
				} else {
					// 用户已经登录,获取该用户的购物车信息
					const res = await this.postdata("/api/Goods/CartList");
					if (res.code == 200) {
						this.setShoppingCart(res.data.items);
					} else {
						// 提示失败信息
						this.notifyError(res.msg);
					}
				}
			},
		},
		methods: {
			...mapActions(["setUser", "setShowLogin", "setShoppingCart"]),

			login() {
				// 点击登录按钮, 通过更改vuex的showLogin值显示登录组件
				this.setShowLogin(true);
			},
			registergo() {
				sessionStorage.setItem("registerBool", true);
				this.setShowLogin(true);
			},
			goOrder() {
				// 判断是否登录,没有登录则显示登录组件
				if (!this.$store.getters.getUser) {
					this.$store.dispatch("setShowLogin", true);
					return;
				}
				this.$router.push("/PersonalCenter/Order")
			},
			// 退出登录
			logout() {
				this.visible = false;
				// 清空本地登录信息
				localStorage.setItem("userToken", "");
				// 清空vuex登录信息
				this.setUser("");
				this.notifySucceed("成功退出登录");
			},
			// 接收注册子组件传过来的数据
			isRegister(val) {
				this.register = val;
			},
			// 点击搜索按钮
			searchClick() {
				if (this.search != "") {
					// 跳转到全部商品页面,并传递搜索条件
					if (this.temp == "商品") {
						this.$router.push({
							path: "/goods/connector",
							query: {
								search: this.search,
							},
						});
						this.search = "";
					} else {
						this.$router.push({
							path: "/shopList",
							query: {
								search: this.search,
							},
						});
						this.search = "";
					}

				}
			},
			async GetConfig() {
				var res = await this.postdata("/api/News/GetConfig", {
					"queryType": 0
				});
				if (res.code == 200) {
					this.Config = res.data;
				}
			},
			async AgreementList() {
				var res = await this.postdata("/api/News/AgreementList", {
					page: 1,
					limit: 10,
				});
				if (res.code == 200) {
					this.xieyilist = res.data.items
				}
			},
			async loadbangzhuList() {
				var res = await this.postdata("/api/News/HelpCenterList", {
					page: 1,
					limit: 20,
					"parentId": 0
				});
				if (res.code == 200) {
					this.bangzhulist = res.data.items;
					this.bangzhulist.map((item, index) => {
						if (item.Title == '新手入门') {
							this.bangzhulist.map(item2 => {
								console.log(item2.Title)
								if (item2.Title == '售后服务') {
									item2.ChildrenList.unshift(item)
									this.bangzhulist.splice(index, 1)
								}
							})
						}
					})
				}
			},
			async GetUserInfo() {
				const res = await this.postdata("/api/User/GetUser");
				if (res.code == 200) {
					this.setUser(res.data);
				}
			},
			NavToUrl(url) {
				var query = this.getUrlParam(url);
				var path = url.split("?")[0];
				this.$router.push({
					path: path,
					query: query,
				});
			},
			getUrlParam(url) {
				let obj = {};
				let p = url.split("?")[1];
				if (p) {
					let keyValue = p.split("&");
					for (let i = 0; i < keyValue.length; i++) {
						let item = keyValue[i].split("=");
						let key = item[0];
						let value = item[1];
						obj[key] = value;
					}
				}
				return obj;
			},
			flushCom() {
				// this.$router.go(0)
			},
			reload() {
				console.log("reload", 301);
				this.isRouterAlive = false;
				this.$nextTick(() => {
					this.isRouterAlive = true;
				});
			},
			open() {
				this.dropdown = true;
			},
			handleCommand(command) {
				this.temp = command;
			},
			async feedBackClick() {
				if (!this.feedBack.Contents) {
					this.notifyError('请输入建议&投诉内容');
					return false
				}
				const res = await this.postdata("/News/OperationFeedback", this.feedBack);
				if (res.code == 200) {
					this.notifySucceed("成功退出登录");
					this.feedBackClose()
				} else {
					this.notifyError(res.msg);
				}
			},
			feedBackClose() {
				this.feedBackBool = false
				this.feedBack.Contact = ""
				this.feedBack.Contents = ""
			}
		},
	};
</script>

<style scoped>
	.bgF {
		background-color: #fff !important;
	}

	.btmInfo {
		display: flex;
		justify-content: center;
		padding-top: 16px;
	}

	.btmInfo a {
		color: #b6b6b6;
		font-size: 12px;
	}

	.btmInfo .sep {
		color: #b6b6b6;
		padding: 0 10px;
	}

	.btmInfo a:hover {
		color: #fff;
	}
</style>

<!-- 意见反馈start -->
<style>
	.feedback-alert {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 100;
		background: rgba(0, 0, 0, 0.5);
	}

	.feedback-alert .content .form .form-center {
		margin-top: 29px;
	}

	.feedback-alert .content.content-1 {
		height: 460px;
		margin-top: -230px;
	}

	.feedback-alert .content {
		position: fixed;
		width: 614px;
		padding: 0 25px 25px;
		top: -50%;
		left: 50%;
		margin: 0 0 0 -307px;
		z-index: 101;
		background-color: #fff;
		border-radius: 4px;
		color: #333;
	}

	.feedback-alert .content .title {
		font-size: 18px;
		font-weight: 600;
		padding-top: 20px;
	}

	.feedback-alert .content .alert-close {
		position: absolute;
		font-size: 24px;
		color: #666;
		cursor: pointer;
		top: 12px;
		right: 12px;
	}

	.feedback-alert .content .form .form-center {
		margin-top: 29px;
	}

	.feedback-alert .content .form {
		margin: 40px 25px 0 20px;
	}

	.feedback-alert .content .form .form-top {
		margin-left: 13px;
	}

	.feedback-alert .content .form .form-top img {
		height: 56px;
		width: 61px;
		margin-right: 10px;
	}

	.feedback-alert .content .form .form-top .from-title1 {
		font-size: 18px;
		font-weight: 600;
	}

	.formplaceholder {
		color: #333;
	}

	.feedback-alert .content .form .form-center .textarea {
		display: block;
		height: 120px;
		width: 100%;
		padding: 9px;
		border-radius: 4px;
		border: 1px solid #E0E0E0;
		resize: none;
		font-size: 14px;
		color: #000000;
		line-height: 24px;
	}

	.feedback-alert .content .form .form-center .drop-or-down-phone {
		color: #999;
		margin-top: 12px;
		cursor: pointer;
	}

	.feedback-alert .content .form .form-center .phone-input-div {
		margin-top: 10px;
		height: 40px;
		overflow: hidden;
	}

	.feedback-alert .content .form .form-center .phone-input-div span {
		font-size: 14px;
	}

	.va-m {
		vertical-align: middle;
	}

	.feedback-alert .content .form .form-center .phone-input-div input {
		width: 150px;
		height: 40px;
		border-bottom: 1px solid #E0E0E0;
		line-height: 39px;
		color: #000;
		font-size: 14px;
		padding: 0 6px;
	}

	.feedback-alert .content .form .form-center .feedback-btn {
		text-align: center;
		position: absolute;
		bottom: 25px;
		left: 0;
		right: 0;

	}

	.feedback-alert .content .form .form-center .feedback-btn a {
		width: 180px;
		height: 48px;
		line-height: 48px;
		font-size: 16px;
		background-color: #09c867;
		color: #fff;
		border-radius: 2px;
	}
</style>
<!-- 意见反馈end -->

<style>
	.ellipsis {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.line-block {
		display: inline-block;
		zoom: 1;
		letter-spacing: normal;
		word-spacing: normal;
	}

	.boxsiz {
		box-sizing: border-box;
	}

	.common-btn.common-btn-radius {
		border-radius: 2px;
	}

	.common-btn.common-btn-red-white-border {
		border: 1px solid #eb222b;
		color: #eb222b;

	}

	.bold {
		font-weight: 600;
	}

	.common-btn.common-btn-red-border {
		background-color: #eb222b;
		border: 1px solid #eb222b;
		color: #fff;
	}

	.common-banner-tips .Indexes li {
		width: 9px;
		height: 8px;
		background: #ebf3ff;
		border-radius: 5px;
		margin-left: 5px;
		cursor: pointer;
		transition: width linear 0.3s;
	}

	.common-banner-tips .Indexes li.active {
		width: 21px;
		background: #09c867;
	}

	em {
		font-style: normal;
	}

	.fl {
		float: left;
	}

	.fr {
		float: right;
	}

	.clearfix {
		clear: both;
	}

	.redxx {
		color: #ff0000;
	}

	/* 全局CSS */
	.box-bd {
		min-height: 615px;
	}

	* {
		padding: 0;
		margin: 0;
		border: 0;
		list-style: none;
	}

	.clear {
		zoom: 1;
	}

	.clear::before {
		content: "";
		clear: both;
	}

	.clear:after {
		content: ".";
		clear: both;
		height: 0;
		overflow: hidden;
		display: block;
		visibility: hidden;
	}

	#app .el-header {
		/* margin: 16px 0 0; */
		padding-top: 16px;
	}

	#app .el-main {
		min-height: 300px;
		/* padding: 20px; */
		background-color: #f5f5f5;
		/* border: 2px solid #967D67; */
	}

	#app .el-footer {
		padding: 0;
	}

	.pads {
		padding: 0 !important;
	}

	a,
	a:hover {
		text-decoration: none;
	}

	/* 全局CSS END */

	/* 顶部导航栏CSS */

	.topbar {
		height: 30px;
		background-color: #fff;
	}

	.topbar .nav {
		width: 1225px;
		margin: 0 auto;
	}

	.topbar .nav ul {
		float: right;
	}

	.topbar .nav li {
		float: left;
		height: 30px;
		color: #b0b0b0;
		font-size: 12px;
		text-align: center;
		line-height: 30px;
		margin-left: 20px;
		cursor: pointer;
	}

	.topbar .nav .sep {
		color: #b0b0b0;
		font-size: 12px;
		margin: 0 5px;
	}

	.topbar .nav li .el-button {
		color: #666;
		padding-top: 6px;
		padding-bottom: 6px;
	}

	.topbar .nav .el-button:hover {
		color: #ff0000;
	}

	.topbar .nav li a {
		color: #666;
	}

	.topbar .nav a:hover {
		color: #ff0000;
	}

	.topbar .nav .shopCart {
		width: 120px;
		background: #424242;
	}

	.topbar .nav .shopCart:hover {
		background: #fff;
	}

	.topbar .nav .shopCart:hover a {
		color: #ff6700;
	}

	.topbar .nav .shopCart-full {
		width: 120px;
		background: #ff6700;
	}

	.topbar .nav .shopCart-full a {
		color: white;
	}

	.dingdanwoliu {
		position: relative;
	}

	.dingdanwoliu:hover .wuliutupian {
		display: block;
	}

	.wuliutupian {
		display: none;
		position: absolute;
		left: 0;
		background-color: #fff;
		top: 23px;
		z-index: 100;
		border-radius: 6px;
		box-shadow: 0 0 8px #00000050;
	}

	/* 顶部导航栏CSS END */

	/* 顶栏容器CSS */
	.el-header .el-menu {
		max-width: 1225px;
		margin: 0 auto;
	}

	.el-menu.el-menu--horizontal {
		border: none !important;
	}

	.el-header .logo {
		width: 189px;
		float: left;
	}

	.el-header .so {
		width: 670px;
		float: right;
		display: flex;
		position: relative
	}

	.el-header .so .search {
		width: 16px;
		height: 16px;
		background-color: #000;
		position: absolute;
		top: 50%;
		left: 6px;
		transform: translateY(-50%);
	}

	.head1 {
		margin: 12px auto 0 auto;
		width: 100%;

	}

	.head1>.head1-left {
		float: left;
	}

	.head1>.head1-left img {
		display: block;
		width: 60px;
		height: 60px;
		margin-top: 20px;
	}

	.head1 .head1-cen {
		/* margin-left: 187px; */
		/* background-color: beige; */
	}

	.head1 .head1-cen li {
		list-style: none;
		float: left;
		padding: 0 0 0 30px;

	}

	.head1 .head1-cen li:hover>.head2 {
		display: block;
		transition: 0.8s;
	}

	.head1 .head1-cen li a {
		font-size: 15px;
		color: #09c867;
		text-decoration: none;
		padding: 0px 10px;
	}

	.head1 .head1-cen li a:hover {
		color: #09c867;
		/* background: #09c867; */
	}

	.bgcs,
	.head1 .head1-cen .bgcs a {
		/* background:#09c867; */
		color: #000;
	}

	.el-link.is-underline:hover:after {
		content: none !important;
	}

	.header-m .header-r-l .command-searches li {
		cursor: pointer;
		margin: 6px 0 0 30px;
		line-height: 14px;
		font-size: 12px;
	}

	.header-m .header-r-l .command-searches li:first-child {
		margin-left: 0;
	}

	.header-m .header-r-l .command-searches li a {
		color: #8b8ea4;
	}

	.head1 .head1-right {
		float: right;
		line-height: 100px;
	}

	.head1 .head1-right input {
		width: 245px;
		height: 50px;
		padding-left: 10px;
		color: #797979;
		border: 0.5px solid #797979;
	}

	.head1 .head1-right>span {
		float: right;
		display: block;
		width: 52px;
		height: 50px;
		margin-top: 25.5px;
		border: 0.5px solid #797979;
		line-height: 50px;
		text-align: center;
		font-size: 25px;
		cursor: pointer;
	}

	.head1 .head1-right>span:hover {
		background-color: #fe5d28;
		color: aliceblue;
	}

	.head2 {
		width: 100%;
		left: 0;
		display: none;
		height: 250px;
		position: absolute;
		background-color: #ffffff;
		box-shadow: 0 3px 3px #8e8e8e;
		z-index: 80;
	}

	.head2>hr {
		border: 0.2px solid #d4d4d4;
	}

	.head2>div {
		transform: translate(-50%);
		left: 50%;
		width: 1226px;
		height: 247px;
		position: absolute;
	}

	.head2>div>a {
		width: 15%;
		height: 250px;
		text-align: center;
		float: left;
		text-decoration: none;
	}

	.head2>div>a>img {
		width: 90%;
		height: 40%;
		margin-top: 40px;
		border-right: 1px solid #a4a4a4;
	}

	.head2>div>a>h3 {
		font-size: 15px;
		line-height: 30px;
		color: #424242;
		font-weight: normal;
	}

	.head2>div>a>h4 {
		line-height: 24px;
		font-size: 12px;
		color: #ff6700;
		font-weight: normal;
	}

	/* 顶栏容器CSS END */

	/* 底栏容器CSS */
	.footer {
		width: 100%;
		text-align: center;
		background: #2f2f2f;
		padding-bottom: 20px;
	}

	.width1225 {
		width: 1225px;
		margin: 0 auto;
	}

	.footer .ng-promise-box {
		border-bottom: 1px solid #3d3d3d;
		line-height: 145px;
	}

	.footer .ng-promise-box {
		margin: 0 auto;
		border-bottom: 1px solid #3d3d3d;
		line-height: 145px;
	}

	.footer .ng-promise-box .ng-promise p a {
		color: #fff;
		font-size: 20px;
		margin-right: 210px;
		padding-left: 44px;
		height: 40px;
		display: inline-block;
		line-height: 40px;
		text-decoration: none;
		background: url("./assets/imgs/us-icon.png") no-repeat left 0;
	}

	.footer .github {
		height: 50px;
		line-height: 50px;
		margin-top: 20px;
	}

	.footer .github .github-but {
		width: 50px;
		height: 50px;
		margin: 0 auto;
		background: url("./assets/imgs/github.png") no-repeat;
	}

	.footer .mod_help {
		text-align: center;
		color: #888888;
	}

	.footer .mod_help p {
		padding: 20px 0 16px 0;
	}

	.footer .mod_help p a {
		color: #888888;
		text-decoration: none;
	}

	.footer .mod_help p a:hover {
		color: #fff;
	}

	.footer .mod_help p span {
		padding: 0 22px;
	}

	.footer .coty {
		margin-top: 24px;
		color: #888888;
	}

	.HminMoney {
		font-size: 14px;
		color: #000 !important;
	}

	.HMoney {
		font-size: 20px;
		color: #000 !important;
	}

	.HrightMoney {
		font-size: 16px;
		color: #000 !important;
	}

	/* 底栏容器CSS END */
	.text-1 {
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
	}

	.car {
		padding: 0 16px;
		height: 40px;
		background: #09c867;
		border-radius: 2px;
		border: 1px solid #09c867;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: #fff;
		cursor: pointer;

	}

	.el-header .el-input__inner {
		height: 40px !important;
		width: 600px !important;
		border: 2px solid #e23435 !important;
		margin-right: 0 !important;
		border-radius: 0 !important;
		padding: 0 0 0 24px;
	}

	.el-input-group__append .el-button {
		width: 132px;
		height: 33px;
		background: #e23435 !important;
		flex: 1;
		color: #fff !important;
		font-size: 24px;
		/* margin-right: 0 !important; */
		box-sizing: border-box !important;
	}

	.el-input .el-input-group__append {
		padding: 0 !important;
		border-radius: 0 !important;
	}

	.flexal {
		display: flex;
		align-items: center;
	}

	.flexcen {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.flexspb {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.flexcum {
		display: flex;
		flex-direction: column;
	}

	.fal {
		align-items: stretch;
	}

	.el-dropdown-link {
		cursor: pointer;
		color: #409eff;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}

	.el-header .xuan {
		width: 85px;
		height: 33px;
		border: 2px solid #e23435;
		/* display: inline-block; */
		border-right: 0;
		flex-shrink: 0;
		box-sizing: border-box;
		padding-left: 12px;
	}

	.el-header .num {
		width: 18px;
		height: 18px;
		/* margin-left: 6px; */
		border-radius: 50%;
		/* background-color: #ff0000; */
		color: #fff;
		font-size: 12px;
	}

	.mod_service_inner {
		padding: 30px 0;
		border-bottom: 1px solid #ddd;
		display: flex;
		justify-content: center;
	}

	.mod_service_inner ul {
		overflow: hidden;
		height: 42px;
	}

	.mod_service_inner ul li {
		float: left;
		width: 305px;
	}

	.mod_service_inner ul li+li {
		border-left: 1px solid #ddd;
	}

	.mod_service_inner ul li .mod_service_unit {
		position: relative;
		margin: 0 auto;
		padding-left: 45px;
		width: 180px;
	}

	.mod_service_inner ul li .mod_service_unit h5 {
		overflow: hidden;
		position: absolute;
		left: 0;
		top: 0;
		width: 36px;
		height: 42px;
		text-indent: -999px;
		background-image: url(./images/ico_service.png);
	}

	.mod_service_inner ul li .mod_service_unit p {
		overflow: hidden;
		width: 100%;
		height: 42px;
		line-height: 42px;
		font-size: 20px;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: #333;
	}

	.mod_service_duo {
		background-position: 0 0;
	}

	.mod_service_kuai {
		background-position: 0 -43px;
	}

	.mod_service_hao {
		background-position: 0 -86px;
	}

	.mod_service_sheng {
		background-position: 0 -129px;
	}

	.footer-ewmcode {
		overflow: hidden;
		padding: 20px 0;
		display: flex;
		justify-content: center;
	}

	.foot-list-fl {
		float: left;
		width: 940px
	}

	.foot-list-fl ul {
		float: left;
		width: 188px
	}

	.foot-list-fl ul li {
		line-height: 30px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		padding-left: 20px
	}

	.foot-list-fl ul li.foot-th {
		font-size: 18px;
		padding-bottom: 4px;
		color: #333;
	}

	.foot-list-fl ul li a {
		color: #666;
		font-size: 14px;
	}

	.right-contact-us {
		float: right;
		width: 250px;
		padding-left: 20px;
		text-align: center;
	}

	.right-contact-us .title {
		font-weight: normal;
		height: 30px;
		line-height: 30px;
		font-size: 18px;
		color: #333;
	}

	.right-contact-us .phone {
		display: block;
		height: 35px;
		line-height: 35px;
		font-size: 18px;
		color: #4ABA00;
	}

	.right-contact-us .tips {
		margin-bottom: 5px;
		line-height: 20px;
		color: #666;
		font-size: 14px;
		text-align: center;
	}

	.padNo {
		padding: 0;
	}

	.el-button--default {
		margin: 0 !important;
		border-radius: 0 !important;
		width: 70px !important;
		font-size: 14px !important;
		line-height: 40px !important;
		padding: 0 !important;

	}

	.elmain {
		background-color: #f5f5f5;
	}
</style>