<!--
 * @Description: 用户注册组件
 * @Author: hai-27
 * @Date: 2020-02-19 22:20:35
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-01 15:34:34
 -->
<template>
	<div id="register">
		<el-dialog title="注册" width="350px" center :visible.sync="isRegister">
			<el-form :model="RegisterUser" :rules="rules" status-icon ref="ruleForm" class="demo-ruleForm">
				<el-form-item prop="name">
					<el-input prefix-icon="el-icon-user-solid" placeholder="请输入账号" v-model="RegisterUser.name">
					</el-input>
				</el-form-item>
				<el-form-item prop="pass">
					<el-input prefix-icon="el-icon-view" type="password" placeholder="请输入密码"
						v-model="RegisterUser.pass"></el-input>
				</el-form-item>
				<el-form-item prop="confirmPass">
					<el-input prefix-icon="el-icon-view" type="password" placeholder="请再次输入密码"
						v-model="RegisterUser.confirmPass"></el-input>
				</el-form-item>
				<el-checkbox v-model="checked"  class="tiaokuan">
					<span style="color: #333;">已阅读并同意<a :href="$target +'/隐私条款.docx'">《隐私条款》</a><a :href="$target +'/服务协议.docx'">《服务协议》</a></span>
				</el-checkbox>
				<el-form-item>
					<el-button size="medium" type="primary" @click="Register" style="width:100%;">注册</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
	</div>
</template>
<script>
	export default {
		name: "MyRegister",
		props: ["register"],
		data() {
			// 用户名的校验方法
			let validateName = async (rule, value, callback) => {
				if (!value) {
					return callback(new Error("请输入用户名"));
				}
				// 用户名以字母开头,长度在5-16之间,允许字母数字下划线
				const userNameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/;
				if (userNameRule.test(value)) {
					//判断数据库中是否已经存在该用户名
					var res = await this.postdata("/Login/findUserName", {
						queryName: this.RegisterUser.name
					})
					if (res.code == 200) {
						this.$refs.ruleForm.validateField("checkPass");
						return callback();
					} else {
						return callback(new Error(res.msg));
					}
				} else {
					return callback(new Error("字母开头,长度5-16之间,允许字母数字下划线"));
				}
			};
			// 密码的校验方法
			let validatePass = (rule, value, callback) => {
				if (value === "") {
					return callback(new Error("请输入密码"));
				}
				// 密码以字母开头,长度在6-18之间,允许字母数字和下划线
				const passwordRule = /^[a-zA-Z]\w{5,17}$/;
				if (passwordRule.test(value)) {
					this.$refs.ruleForm.validateField("checkPass");
					return callback();
				} else {
					return callback(
						new Error("字母开头,长度6-18之间,允许字母数字和下划线")
					);
				}
			};
			// 确认密码的校验方法
			let validateConfirmPass = (rule, value, callback) => {
				if (value === "") {
					return callback(new Error("请输入确认密码"));
				}
				// 校验是否以密码一致
				if (this.RegisterUser.pass != "" && value === this.RegisterUser.pass) {
					this.$refs.ruleForm.validateField("checkPass");
					return callback();
				} else {
					return callback(new Error("两次输入的密码不一致"));
				}
			};
			return {
				checked:false,
				isRegister: false, // 控制注册组件是否显示
				RegisterUser: {
					name: "",
					pass: "",
					confirmPass: ""
				},
				// 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
				rules: {
					name: [{
						validator: validateName,
						trigger: "blur"
					}],
					pass: [{
						validator: validatePass,
						trigger: "blur"
					}],
					confirmPass: [{
						validator: validateConfirmPass,
						trigger: "blur"
					}]
				}
			};
		},
		watch: {
			// 监听父组件传过来的register变量，设置this.isRegister的值
			register: function(val) {
				if (val) {
					this.isRegister = val;
				}
			},
			// 监听this.isRegister变量的值，更新父组件register变量的值
			isRegister: function(val) {
				if (!val) {
					this.$refs["ruleForm"].resetFields();
					this.$emit("fromChild", val);
				}
			}
		},
		methods: {
			Register() {
				if(!this.checked){
					this.notifyError('请勾选隐私条款');
					return false
				}
				// 通过element自定义表单校验规则，校验用户输入的用户信息
				this.$refs["ruleForm"].validate(async valid => {
					//如果通过校验开始注册
					if (valid) {
						var res = await this.postdata("/Login/RegisterUser", {
							UserName: this.RegisterUser.name,
							password: this.RegisterUser.pass
						})
						if (res.code == 200) {
							// 隐藏注册组件
							this.isRegister = false;
							// 弹出通知框提示注册成功信息
							this.notifySucceed(res.msg);
						} else {
							// 弹出通知框提示注册失败信息
							this.notifyError(res.msg);
						}
					} else {
						return false;
					}
				});
			}
		}
	};
</script>
<style scoped>
	.tiaokuan{
		margin:16px 0;
	}
</style>
