<!--
 * @Description: 登录组件
 * @Author: hai-27
 * @Date: 2020-02-19 20:55:17
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-01 15:34:08
 -->
<template>
	<div id="myLogin" class="myLogin">

		<el-dialog title="" width="614px" hegiht="600px" center :visible.sync="isLogin">
			<el-form status-icon ref="ruleForm" class="demo-ruleForm">
				<div id="myLogin" class="account-mask">
					<div class="content" style="top: 50%;" v-if="account_nav==0">
						<div class="fl account-logo"> <img src="/pc_static/img/logo.png" alt="">
						</div>
						<div class="fl account-wrapper boxsiz">
							<div class="login-part" id="accountLogin">
								<div class="account-nav-title clear">
									<!-- <p class="fl boxsiz switch-p" :class="account_current1 == 0?'active':''"
										@click="account_current1=0">免密登录</p> -->
									<p class="fl boxsiz switch-p " :class="account_current1 == 1?'active':''"
										@click="account_current1=1">密码登录</p>
								</div>
								<!-- 免密登录 -->
								<div class="account-form noPasswordLogin" v-if="account_current1==0">
									<p class="account-account-input clear"><span class="fl">+86</span><input
											placeholder="请输入账号" v-model="name" class="fl boxsiz phone-input"></p>
									<p class="account-ver-code clear">
										<input type="text" placeholder="请输入短信验证码" class="fl boxsiz code-input">
										<a href="javascript:void(0)" class="btn fr" v-if="sendBool" id="phoneLoginMsg"
											@click="sendcode">获取验证码</a>
										<a href="javascript:void(0)" class="btn fr disabled" v-else
											id="phoneLoginMsg">{{sendtTime}} &nbsp; 重新获取</a>
									</p>

									<p class="check-input-tip"></p><a href="javascript:void(0)"
										class="btn account-login-btn">登录</a>
									<p class="account-alert-bottom clear"><a href="javascript:void(0)"
											@click="account_nav=1"
											class="fl register-a">立即注册</a><!-- <span class="fr tips">未注册用户登录后直接注册</span> -->
									</p>
								</div>


								<!-- 账号密码登录 -->
								<form class="account-form account-form-hidden passwordLogin" v-if="account_current1==1">
									<p class="account-account-input clear"><span class="fl">手机号：</span><input
											type="text" v-model="name" placeholder="请输入手机号码"
											class="fl boxsiz account-input"></p>
									<p class="account-password-input clear"><span class="fl">密码：</span><b
											class="fl pass-and-text i-show"><input type="password" placeholder="请输入密码"
												v-model="pass" class="boxsiz password-input pass-txt-ipt"></b><i
											class="fl iconfont iconicons_eye_n password-eye"></i></p>
									<p class="check-input-tip"></p><a href="javascript:void(0)" @click="Login"
										class="btn account-login-btn">登录</a>
									<p class="account-alert-bottom clear"><a href="javascript:void(0)"
											@click="account_nav=1" class="fl register-a">立即注册</a>
										<!-- <a href="javascript:void(0)" class="fr forget-a">忘记密码？</a> -->
									</p>
								</form>
								<div class="line liney clearfix">
									<label class="linel">&nbsp;</label>
									<div class="liner">
										<div class="clearfix checkcon">
											<p class="fn-fl checktxt"
												style="display: flex;align-items: center;justify-content: center;">
												<input type="checkbox" class="iyes fn-fl J_protocal" checked
													style="margin-right: 12px; " v-model="checked">
												<span class="fn-fl">我已阅读并同意</span>
												<!-- <a class="itxt fn-fl"
													:href="$target +'/服务协议.docx'" target="_blank">《服务条款》</a> -->

												<a class="itxt fn-fl" href="/#/agreement?id=1"
													target="_blank">《用户协议》</a>和
												<a class="itxt fn-fl" href="/#/agreement?id=4"
													target="_blank">《隐私协议》</a>
											</p>
											<p class="fn-fl errorbox v-txt" id="protocalBox"></p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 	<p class="iconfont iconguanbi close-icon account-alert-close">X</p> -->




					</div>
					<div class="content" style="top: 50%;" v-if="account_nav==1">
						<div class="fl account-logo"> <img src="/pc_static/img/logo.png" alt="">
						</div>
						<div class="fl account-wrapper boxsiz">
							<div class="register-part" id="accountRegAndForgetPassword"><a
									href="http://ReasonableSpread.com" rel="noopener noreferrer nofollow"><img
										border="0px"
										src="http://track5.rspread.net/ConversionTracking.aspx?userid=70110&amp;type=p&amp;value=0"
										style="height:0;width:0"></a>
								<div class="account-nav-title clear">
									<p class="fl boxsiz switch-p active">账号注册</p>
									<!-- <p class="fl boxsiz switch-p ">邮箱注册</p> -->
								</div>
								<div class="account-form phoneReg">
									<p class="account-account-input clear"><span class="fl">手机号</span><input
											placeholder="请输入手机号" v-model="name" class="fl boxsiz phone-input"></p>

									<p class="account-account-input clear"><span class="fl">设置密码：</span><input
											type="password" v-model="pass" placeholder="请输入密码" maxlength="16"
											class="fl boxsiz phone-input"></p>

									<p class="account-account-input clear"><span class="fl">确认密码：</span><input
											type="password" v-model="pass2" placeholder="请再次输入密码" maxlength="16"
											class="fl boxsiz phone-input"></p>

									<p class="account-ver-code clear">
										<input type="text" placeholder="请输入短信验证码" class="fl boxsiz code-input"
											v-model="smscode">
										<a href="javascript:void(0)" class="btn fr" v-if="sendBool" id="phoneLoginMsg"
											@click="sendcode">获取验证码</a>
										<a href="javascript:void(0)" class="btn fr disabled" v-else
											id="phoneLoginMsg">{{sendtTime}} &nbsp; 重新获取</a>
									</p>
									<p class="check-input-tip"></p><a href="javascript:void(0)" class="btn next-btn"
										@click="Register" data-type="1">立即注册</a>
									<p class="account-alert-bottom clear to-login-a-bottom"> <a
											href="javascript:void(0)" @click="account_nav=0"
											class="fl to-login-a">返回登录</a></p>
									<div class="line liney clearfix">
										<label class="linel">&nbsp;</label>
										<div class="liner">
											<div class="clearfix checkcon">
												<p class="fn-fl checktxt"
													style="display: flex;align-items: center;justify-content: center;">
													<input type="checkbox" class="iyes fn-fl J_protocal" checked
														style="margin-right: 12px; " v-model="checked">
													<span class="fn-fl">我已阅读并同意</span>
													<!-- <a class="itxt fn-fl"
														:href="$target +'/服务协议.docx'" target="_blank">《服务条款》</a> -->

													<a class="itxt fn-fl" href="/#/agreement?id=1"
														target="_blank">《用户协议》</a>和
													<a class="itxt fn-fl" href="/#/agreement?id=4"
														target="_blank">《隐私协议》</a>
												</p>
												<p class="fn-fl errorbox v-txt" id="protocalBox"></p>
											</div>
										</div>
									</div>
								</div>



								<!-- <div class="account-form account-form-hidden emailReg">
									<p class="account-account-input clear"><span class="fl">邮箱：</span><input type="text"
											placeholder="请输入邮箱账号" class="fl boxsiz email-input"></p>
									<p class="account-ver-code clear"><input type="text" placeholder="请输入邮箱验证码"
											class="fl boxsiz code-input"><a href="javascript:void(0)" class="btn disabled fr"
											id="emailRegMsg">获取邮箱验证码</a></p>
									<p class="check-input-tip"></p><a href="javascript:void(0)" class="btn next-btn disabled"
										data-type="2">下一步</a>
									<p class="account-alert-bottom clear to-login-a-bottom"> <a href="javascript:void(0)" @click="account_nav=0"
											class="fl to-login-a">返回登录</a></p>
									<p class="account-agreement"><i
											class="iconfont iconselect_multiplechoice_y is-check va-m check"></i><b>我已阅读并同意《<a
												href="https://www.icdeal.com/agreement.html" target="_blank">百能云芯用户协议</a>》</b></p>
								</div> -->
							</div>
						</div> <!-- <p class="iconfont iconguanbi close-icon account-alert-close" >X</p> -->
					</div>

				</div>
			</el-form>
		</el-dialog>
	</div>
</template>
<script>
	import {
		mapActions
	} from "vuex";
	import {
		setToken
	} from '@/utils/auth'

	export default {
		name: "MyLogin",
		data() {
			// 用户名的校验方法
			const regEmail = /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
			const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
			let validateName = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("请输入用户名"));
				}
				// 用户名以字母开头,长度在5-16之间,允许字母数字下划线
				const userNameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/;
				if (regEmail.test(value) || regMobile.test(value)) {
					this.$refs.ruleForm.validateField("checkPass");
					return callback();
				} else {
					return callback(new Error("请输入正确的手机号或者邮箱"));
				}
			};


			// 密码的校验方法
			let validatePass = (rule, value, callback) => {
				if (value === "") {
					return callback(new Error("请输入密码"));
				}
				this.$refs.ruleForm.validateField("checkPass");
				return callback();
				// 密码以字母开头,长度在6-18之间,允许字母数字和下划线
				// const passwordRule = /^[a-zA-Z]\w{5,17}$/;
				// if (passwordRule.test(value)) {
				// 	this.$refs.ruleForm.validateField("checkPass");
				// 	return callback();
				// } else {
				// 	return callback(
				// 		new Error("字母开头,长度6-18之间,允许字母数字和下划线")
				// 	);
				// }
			};
			return {
				current: 0,
				// LoginUser: {
				// 	name: "111",
				// 	pass: "",
				// },
				checked: false,
				isRegister: false, // 控制注册组件是否显示
				name: "",
				pass: "",
				pass2: "",
				smscode: "",

				// 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
				// rules: {
				// 	name: [{
				// 		validator: validateName,
				// 		trigger: "blur"
				// 	}],
				// 	pass: [{
				// 		validator: validatePass,
				// 		trigger: "blur"
				// 	}],
				// 	confirmPass: [{
				// 		validator: validateConfirmPass,
				// 		trigger: "blur"
				// 	}]
				// },

				account_nav: 0,
				account_current1: 1,
				account_current2: 0,
				sendtTime: 30,
				sendBool: true,
				time:null
			};
		},
		computed: {
			// 获取vuex中的showLogin，控制登录组件是否显示
			isLogin: {
				get() {
					var bool = sessionStorage.getItem("registerBool");
					if (bool) {
						this.account_nav = 1
					} else {
						this.account_nav = 0
					}
					sessionStorage.removeItem("registerBool");
					return this.$store.getters.getShowLogin;
				},
				set(val) {
					this.$refs["ruleForm"].resetFields();
					this.setShowLogin(val);
				},

				// var bool = sessionStorage.getItem("registerBool");
				// console.log(bool,266666666)
				// if(bool){
				// 	this.account_nav = 1
				// }
				// else{
				// 	this.account_nav = 0
				// }
				// sessionStorage.removeItem("registerBool");
				// console.log(this.account_nav,266666666)
			}
		},
		methods: {
			...mapActions(["setUser", "setShowLogin"]),
			async Login() {
				if (!this.checked) {
					this.notifyError("请勾选隐私条款");
					return false;
				}
				const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/

				if (!regMobile.test(this.name)) {
					this.notifyError('请输入正确的手机号或者邮箱');
					return
				}
				// 通过element自定义表单校验规则，校验用户输入的用户信息
				// this.$refs["ruleForm"].validate(async (valid) => {
				//如果通过校验开始登录
				// if (valid) {
				var res = await this.postdata("/api/Login/WebLogin", {
					UserName: this.name,
					password: this.pass,
				});
				if (res.code == 200) {
					// 隐藏登录组件
					this.isLogin = false;
					// 登录信息存到本地
					localStorage.setItem("userToken", res.data);
					setToken(res.data);
					// 弹出通知框提示登录成功信息
					this.notifySucceed("登录成功");
					const res1 = await this.postdata("/api/User/GetUser");
					if (res1.code == 200) {
						this.setUser(res1.data);
						this.$router.replace('/')
					}
				} else {
					// 清空输入框的校验状态
					// this.$refs["ruleForm"].resetFields();
					// 弹出通知框提示登录失败信息
					this.notifyError(res.msg);
				}
				// } else {
				//   return false;
				// }
				// });
			},

			async Register() {
				if (!this.checked) {
					this.notifyError('请勾选隐私条款');
					return false
				}
				const regName = /^[a-zA-Z0-9_]+$/
				if (!regName.test(this.name)) {
					this.notifyError('请输入正确的用户名，只能包含字符、数字和下划线');
					return
				}

				if (this.pass == "" || this.pass2 == "") {
					this.notifyError('请输入密码');
					return
				}
				if (this.pass2 != this.pass) {
					this.notifyError('两次输入的密码不一致');
					return
				}
				if (!this.smscode || this.smscode == '') {
					this.$message({
						message:'验证码不能为空',
						type:'error'
					})
					//this.notifyError('验证码不能为空');					
					return;
				}
				// 通过element自定义表单校验规则，校验用户输入的用户信息
				// this.$refs["ruleForm"].validate(async valid => {
				//如果通过校验开始注册
				// if (valid) {
				var res = await this.postdata("/api/Login/RegisterUser", {
					UserName: this.name,
					password: this.pass,
					smscode: this.smscode
				})
				if (res.code == 200) {
					// 隐藏注册组件
					this.isLogin = false;
					// 弹出通知框提示注册成功信息
					this.notifySucceed(res.msg);
					// this.$router.push({
					// 	path: '/login',
					// });
				}
				// else {
				// 	// 弹出通知框提示注册失败信息
				// 	this.notifyError(res.msg);
				// }

				// } else {
				// 	return false;
				// }
				// });
			},

			async sendcode() {
				var that = this
				// const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
				// if (!regMobile.test(this.name)) {
				// 	this.notifyError('请输入正确的手机号');
				// 	return
				// }
				var res = await this.postdata("/api/Login/SendCode", {
					Mobile: this.name,
					SmsType: 0,
				})
				if (res.code == 200) {
					this.notifySucceed('发送成功');
					this.sendBool = false
					this.time = setInterval(function() {
						that.sendtTime--
						if (that.sendtTime <= 0) {
							that.sendBool = true
							that.sendtTime = 30
							clearTimeout(that.time)
						}
					}, 1000)
				} else {
					this.notifyError('发送失败');
				}

			},
		}
	};
</script>
<style>
	.myLogin .el-dialog__header {
		padding: 0 !important;
	}

	.myLogin .el-dialog__body {
		padding: 0 !important;
	}

	.account-mask .content {

		height: 480px;
		width: 614px;

	}

	.account-mask .content .account-logo {
		width: 230px;
		background-color: #fff;
		height: 100%;
		border-radius: 4px 0px 0px 4px;
		text-align: center;
	}

	.account-mask .content .account-logo img {
		height: 77px;
		width: 129px;
		margin-top: 194px;
	}

	.account-mask .content .account-wrapper {
		width: 384px;
		background-color: #fff;
		height: 100%;
		border-radius: 0px 4px 4px 0px;
		padding-top: 70px;
	}

	.account-mask .content .account-alert-close {
		font-size: 24px;
		position: absolute;
		right: 12px;
		top: 12px;
		cursor: pointer;
	}

	.account-mask .content .account-wrapper .account-nav-title p.active {
		font-weight: 600;
		font-size: 18px;
		color: #000;
		padding-bottom: 13px;
		border-bottom: 4px solid #00C661;
	}

	.account-mask .content .account-wrapper .account-nav-title p {
		font-size: 16px;
		color: #666;
		cursor: pointer;
		margin-left: 29px;
		height: 41px;
		transition: all linear 0.2s;
	}

	.account-mask .content .account-wrapper .account-form {
		padding-top: 20px;
		margin: 0 auto;
		width: 320px;
	}

	.account-mask .content .account-wrapper .account-account-input,
	.account-mask .content .account-wrapper .account-password-input,
	.account-mask .content .account-wrapper .account-see-password-input {
		font-size: 14px;
		height: 39px;
		width: 320px;
		border-bottom: 1px solid #E4E7ED;
		line-height: 39px;
	}

	.account-mask .content .account-wrapper .account-account-input span,
	.account-mask .content .account-wrapper .account-password-input span,
	.account-mask .content .account-wrapper .account-see-password-input span {
		color: #333;
		display: block;
		width: 70px;
	}

	.account-mask .content .account-wrapper .account-account-input input,
	.account-mask .content .account-wrapper .account-password-input input,
	.account-mask .content .account-wrapper .account-see-password-input input {
		width: 250px;
	}

	.account-mask .content .account-wrapper input {
		color: #000;
		border: none;
		background-color: #fff;
		padding: 0 4px;
		line-height: 39px;
		height: 39px;
	}

	.account-mask .content .account-wrapper .account-ver-code {
		height: 40px;
		width: 320px;
		margin-top: 20px;

	}

	.account-mask .content .account-wrapper .account-ver-code input {
		width: 210px;
		border-bottom: 1px solid #E4E7ED;

	}

	.account-mask .content .account-wrapper input {
		color: #000;
		border: none;
		background-color: #fff;
		padding: 0 4px;
		line-height: 39px;
		height: 39px;
	}

	.account-mask .content .account-wrapper .btn.disabled {
		background-color: #E0E0E0;
	}

	.account-mask .content .account-wrapper .account-ver-code a {
		background-color: #09C867;
		width: 100px;
	}

	.account-mask .content .account-wrapper .btn {
		border-radius: 4px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		color: #fff;
		cursor: pointer;
		display: block;
	}

	.account-mask .content .account-wrapper .btn.disabled {
		background-color: #E0E0E0;
	}

	.account-mask .content .account-wrapper .account-login-btn,
	.account-mask .content .account-wrapper .next-btn {
		margin-top: 20px !important;
		font-size: 14px;
	}

	.account-mask .content .account-wrapper .btn {
		border-radius: 4px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		color: #fff;
		cursor: pointer;
		display: block;
		background-color: #09C867;
	}

	.account-mask .content .account-wrapper .account-alert-bottom a {
		font-size: 14px;
		color: #333;
	}

	.account-mask .content .account-wrapper .account-alert-bottom.to-login-a-bottom {
		padding-top: 20px;
	}

	.account-mask .content .account-wrapper .account-alert-bottom {
		padding-top: 20px;
	}
</style>


<style>
	.header {
		width: 1240px;
		height: 93px;
		margin: 0 auto;
		background-color: #fff;
		padding-left: 60px;
	}

	.deng a {
		margin-top: 8px;
		color: rgb(0, 47, 255);
	}

	.m-fnbox {
		border: 1px solid #d5d5d5;
		background: #fff;
		padding: 35px;
		clear: both;
		margin-bottom: 50px
	}

	.fnlogin {
		font-family: 'Microsoft YaHei';
		font-size: 12px;
		color: #3c3c3c;
		/* padding-left:60px; */
		height: 305px;
		margin: 0 auto;
		width: 540px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

	}

	.fnlogin input {
		margin: 0px;
		padding: 0px;
	}

	.fnlogin .ltit {
		color: #333;
		padding-bottom: 37px;
		font-size: 14px;
		font-weight: bold;
	}

	.fnlogin .inp {
		float: left;
		border: 1px solid #ccc;
		padding: 8px;
		height: 18px;
		line-height: 18px;
		margin-right: 10px;
		font-family: 'Microsoft YaHei';
		outline: 0 none;
		font-size: 14px;
		vertical-align: middle;
		width: 290px;
	}

	::-webkit-input-placeholder {
		color: #ccc;
	}

	:-moz-placeholder {
		color: #ccc;
	}

	::-moz-placeholder {
		color: #ccc;
	}

	:-ms-input-placeholder {
		color: #ccc;
	}

	.fnlogin .fcolor {
		color: #3c3c3c;
	}

	.fnlogin .red {
		padding-left: 10px;
	}

	.fnlogin .nred {
		border: 1px solid #DA3A4C;
	}

	/* .fnlogin .success{
    background:url(../images/fn-icon.png) 280px -89px no-repeat;
}
.fnlogin .error{
    background:url(../images/fn-icon.png) 126px 10px no-repeat;
} */
	.fnlogin .success.fcode,
	.fnlogin .success.imgcode {
		background-position: 125px -89px;
	}

	.fnlogin .error.fmobile,
	.fnlogin .error.femail,
	.fnlogin .error.fpass,
	.fnlogin .error.fsecpass {
		background-position: 280px 10px;
	}

	.fnlogin .noticebox {
		width: 227px;
		color: #DA3A4C;
		background: #fff8e1;
		border: 1px solid #fddbaa;
		height: 32px;
		line-height: 15px;
		padding: 2px 8px 0px 8px;
	}

	.fnlogin .noticebox2 {
		width: 227px;
		color: #DA3A4C;
		background: #fff8e1;
		border: 1px solid #fddbaa;
		height: 32px;
		line-height: 32px;
		padding: 2px 8px 0px 8px;
	}

	.fnlogin .errorbox {
		width: 300px;
		color: #DA3A4C;
		background: #ffebeb;
		border: 1px solid #ffbdbe;
		height: 18px;
		line-height: 18px;
		padding: 8px;
	}

	.fnlogin .v-captcha {
		display: inline-block;
		*display: inline;
		*zoom: 1;
		float: left;
		margin-right: 10px;
		height: 34px;
		line-height: 34px;
	}

	.fnlogin .v-captcha img {
		display: inline;
		float: left;
		width: 85px;
		height: 34px;
		margin-right: 10px;
	}

	.fnlogin .v-captcha a {
		color: #0066cc;
		cursor: pointer;
	}

	.fnlogin .fcode,
	.regcon .imgcode {
		width: 137px;
	}

	.fnlogin .icode {
		display: block;
		text-align: center;
		line-height: 35px;
		background: #f5f5f5;
		border: 1px solid #ddd;
		border-radius: 1px;
		height: 35px;
		color: #3c3c3c;
		margin: 0 30px 0 5px;
		width: 115px;
		outline: 0 none;
		cursor: pointer;
		font-family: 'Microsoft YaHei';
		font-size: 12px;
	}

	.fnlogin .icode:hover {
		text-decoration: underline;
	}

	.fnlogin .disabled.icode {
		color: #ccc;
		cursor: default;
	}

	.fnlogin .disabled.icode:hover {
		text-decoration: none;
	}

	.fnlogin .line {
		height: 37px;
		padding-bottom: 25px;
	}

	.fnlogin .liney {
		height: auto;
		padding-bottom: 0px;
	}

	.fnlogin .line .dt {
		display: inline-block;
		*display: inline;
		*zoom: 1;
		height: 34px;
		line-height: 34px;
		padding-left: 10px;
		/* background:url(../images/fn-icon.png) 0px -137px no-repeat; */
	}

	.fnlogin .line .linel {
		float: left;
		text-align: right;
		width: 90px;

	}

	.fnlogin .line .liner {
		float: left;
	}

	.show-voice {
		width: 700px;
		line-height: 20px;
		font-size: 12px;
		color: #999;
		padding-left: 85px;
		float: left;
		display: none;
	}

	.fnlogin .v-txt,
	.regcon .ui_panel,
	.fnlogin .v-txt2 {
		display: none;
	}

	.fnlogin .safeline {
		clear: both;
		padding-top: 4px;
		padding-left: 49px;
		color: #666;
	}

	.fnlogin .safeline .sz {
		display: inline-block;
		*display: inline;
		*zoom: 1;
		color: #fff;
		background: #d5d5d5;
		width: 65px;
		height: 15px;
		text-align: center;
		line-height: 15px;
		margin-left: 1px;
	}

	.fnlogin .safeline .son {
		background: #DA3A4C;
	}

	.fnlogin .iyes {
		margin-right: 5px;
		margin-top: 2px;
	}

	.fnlogin .checkcon {
		height: 34px;
	}

	.fnlogin .checkcon .checktxt {
		height: 18px;
		padding-right: 106px;
		padding-top: 10px;
	}

	.fnlogin .itxt {
		color: #0066cc;
		text-decoration: none;
	}

	.fnlogin .itxt:hover {
		text-decoration: underline;
	}

	.fnlogin .regbtn {
		display: block;
		clear: both;
		cursor: pointer;
		margin: 20px 0px 10px 0;
		width: 250px;
		height: 40px;
		color: #fff;
		font-family: 'Microsoft YaHei';
		font-size: 18px;
		text-align: center;
		line-height: 40px;
		background: #DA3A4C;
		text-decoration: none;
	}

	.fnlogin .regbtn:hover {
		background: #ce0c22;
	}
</style>
<style scoped>
	.header {
		width: 1240px;
		height: 93px;
		margin: 0 auto;
		background-color: #fff;
		padding-left: 60px;
	}

	.cent {
		width: 100%;
		height: 487px;
		background: #bf1919;
	}

	.box {
		width: 1240px;
		height: 100%;
		margin: 0 auto;
	}

	.m-login .login-form {
		float: right;
		width: 306px;
		padding: 20px;
		background-color: #fff;
		margin-top: 40px;
	}

	.m-login .login-form .title {
		height: 21px;
	}

	.m-login .login-form .title h1 {
		font-size: 16px;
		color: #666;
	}

	.m-login .login-form .title .regist-link a {
		font-size: 14px;
		color: #da3a4c;
	}

	.m-login .login-form .title .regist-link a:hover {
		text-decoration: underline;
	}

	.m-login .login-form .u-msg-wrap {
		padding: 5px 0 10px;
		font-family: "Microsoft YaHei";
	}

	.m-login .login-form .u-msg-wrap .msg {
		height: 24px;
		line-height: 24px;
		padding: 0 10px 0 30px;
		position: relative;
		color: #da3a4c;
	}

	.m-login .login-form .u-msg-wrap .msg.msg-warn {
		border: 1px solid #ffe9b1;
		background: #fff4d8;
	}

	/* .m-login .login-form .u-msg-wrap .msg.msg-warn i {
  background: url(../images/fn-icon.png) no-repeat 0 0;
} */
	.m-login .login-form .u-msg-wrap .msg.msg-err {
		border: 1px solid #ffbdbe;
		background: #ffebeb;
		display: none;
	}

	.m-login .login-form .u-msg-wrap .msg.msg-err i {
		background: url(../images/dialog-login.png) no-repeat 0 -40px;
	}

	.m-login .login-form .u-msg-wrap .msg i {
		display: block;
		width: 15px;
		height: 15px;
		position: absolute;
		left: 10px;
		top: 50%;
		margin-top: -7px;
	}

	.m-login .login-form .u-input {
		position: relative;
	}

	.m-login .login-form .u-input .u-txt {
		width: 246px;
		height: 18px;
		border: 1px solid #ccc;
		padding: 10px 10px 10px 47px;
		font-size: 14px;
	}

	.m-login .login-form .u-input .u-txt.u-txt-err {
		border-color: #da3a4c;
	}

	.m-login .login-form .u-input .u-txt.u-txt-focus {
		border-color: #999;
	}

	.m-login .login-form .u-input .u-label {
		width: 41px;
		height: 38px;
		border-right: 1px solid #eee;
		position: absolute;
		left: 1px;
		top: 1px;
	}

	.m-login .login-form .u-input .u-name {
		background: url(../images/dialog-login.png) no-repeat 0 0;
	}

	.m-login .login-form .u-input .u-pwd {
		background: url(../images/dialog-login.png) no-repeat -39px 0;
	}

	.m-login .login-form .u-safe {
		position: relative;
		margin: 12px 0;
	}

	.m-login .login-form .u-safe .auto label {
		color: #666;
		cursor: pointer;
	}

	.m-login .login-form .u-safe .u-ckb {
		margin-right: 10px;
		vertical-align: middle;
	}

	.m-login .login-form .u-safe .forget {
		position: absolute;
		right: 0;
		top: 0;
	}

	.m-login .login-form .u-safe .forget a {
		color: #666;
	}

	.m-login .login-form .u-safe .forget a:hover {
		text-decoration: underline;
	}

	.m-login .login-form .u-btn a {
		display: block;
		width: 305px;
		height: 40px;
		line-height: 40px;
		border: none;
		color: #fff;
		font-size: 18px;
		text-align: center;
		font-family: "Microsoft YaHei";
		background-color: #bf1919;
		cursor: pointer;
		text-decoration: none;
	}

	/* .m-login .login-form .u-btn a:hover {
  background-color: #cf0c22;
} */
	.m-login .login-form .u-authcode {
		position: relative;
	}

	.m-login .login-form .u-authcode .u-txt {
		width: 88px;
		padding: 10px 10px;
		vertical-align: middle;
		font-size: 14px;
		text-align: center;
	}

	.m-login .login-form .u-authcode .u-txt.u-txt-err {
		border-color: #da3a4c;
	}

	.m-login .login-form .u-authcode .u-txt.u-txt-focus {
		border-color: #999;
	}

	.m-login .login-form .u-authcode .code-img {
		width: 66px;
		height: 40px;
		vertical-align: middle;
	}

	.m-login .login-form .u-authcode a {
		color: #2176dd;
	}

	.m-login .login-form .u-authcode a:hover {
		text-decoration: underline;
	}

	.m-login .login-form .account {
		height: 30px;
		line-height: 30px;
		color: #999;
		word-spacing: -3px;
	}

	.m-login .login-form .account dt,
	.m-login .login-form .account dd {
		display: inline-block;
		*display: inline;
		*zoom: 1;
		vertical-align: top;
	}

	.m-login .login-form .account dd a {
		display: block;
		width: 30px;
		height: 30px;
		margin-left: 6px;
	}

	.m-login .login-form .account .qq:hover {
		background-position: -30px 0;
	}

	#login-form .co_ye {
		color: #ea5e00;
		margin-left: 6px;
		vertical-align: middle;
	}

	.fnlogin .line {
		height: 37px;
		padding-bottom: 25px;
	}

	.fnlogin .liney {
		height: auto;
		padding-bottom: 0px;
	}

	.fnlogin .line .dt {
		display: inline-block;
		*display: inline;
		*zoom: 1;
		height: 34px;
		line-height: 34px;
		padding-left: 10px;
		/* background:url(../images/fn-icon.png) 0px -137px no-repeat; */
	}

	.fnlogin .line .linel {
		float: left;
		text-align: right;
		width: 90px;
	}

	.fnlogin .line .liner {
		float: left;
	}

	.show-voice {
		width: 700px;
		line-height: 20px;
		font-size: 12px;
		color: #999;
		padding-left: 85px;
		float: left;
		display: none;
	}

	.fnlogin .v-txt,
	.regcon .ui_panel,
	.fnlogin .v-txt2 {
		display: none;
	}

	.fnlogin .safeline {
		clear: both;
		padding-top: 4px;
		padding-left: 49px;
		color: #666;
	}

	.fnlogin .safeline .sz {
		display: inline-block;
		*display: inline;
		*zoom: 1;
		color: #fff;
		background: #d5d5d5;
		width: 65px;
		height: 15px;
		text-align: center;
		line-height: 15px;
		margin-left: 1px;
	}

	.fnlogin .safeline .son {
		background: #da3a4c;
	}

	.fnlogin .iyes {
		margin-right: 5px;
		margin-top: 2px;
	}

	.fnlogin .checkcon {
		height: 34px;
	}

	.fnlogin .checkcon .checktxt {
		height: 18px;
		padding-right: 106px;
		padding-top: 10px;
	}

	.fnlogin .itxt {
		color: #0066cc;
		text-decoration: none;
	}

	.fnlogin .itxt:hover {
		text-decoration: underline;
	}

	.fnlogin .regbtn {
		display: block;
		clear: both;
		cursor: pointer;
		margin: 20px 0px 10px 0;
		width: 250px;
		height: 40px;
		color: #fff;
		font-family: "Microsoft YaHei";
		font-size: 18px;
		text-align: center;
		line-height: 40px;
		background: #da3a4c;
		text-decoration: none;
	}

	.fnlogin .regbtn:hover {
		background: #ce0c22;
	}
</style>