
<template>
  <div id="myList" class="myList">
    <ul>
      <li v-for="(item,indexs) in list" :key="item.Id">
        <el-popover placement="top">
          <p>确定删除吗？</p>
          <div style="text-align: right; margin: 10px 0 0">
            <el-button type="primary" size="mini" @click="deleteCollect(item.Id)">确定</el-button>
          </div>
          <i class="el-icon-close delete" slot="reference" v-show="isDelete"></i>
        </el-popover>
        <router-link :to="{ path: '/goods/details', query: { productID: item.Id } }">
          <img :src="getUrl(item.imgList[item.tempIndex])" alt />
          <div class="xs_slide">
            <div class="small-xs-shop">
              <ul>
                <li v-for="(items, index) in item.imgList" :key="index" style="padding: 0">
                  <a href="javascript:void(0);" @mouseenter="mouseenter(index, indexs)">
                    <img class="lazy-list" :src="items" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <h2>{{ item.Name }}</h2>
          <h3>{{ item.ShopName }}</h3>
          <p>
            <span>￥{{ item.Price }}元</span>
            <span v-show="item.Price != item.MarkPrice" class="del">{{ item.MarkPrice }}元</span>
          </p>
        </router-link>
        <div class="flexal flexcen">
          <!-- <div class="inp"><input type="number" style="width: 100%;height: 100%;" placeholder="1"  min="1" v-model="item.num"></div> -->
          <div style="border: 1px solid#ccc;
              color: #333;
              width: 80px;
              height: 30px;
              cursor: pointer;
            " class="flexcen" @click.prevent="jia(item)">
            加入购物车
          </div>
        </div>
      </li>
      <li v-show="isMore && list.length >= 1" id="more">
        <router-link :to="{ path: '/goods', query: { categoryID: categoryID } }">
          浏览更多
          <i class="el-icon-d-arrow-right"></i>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "MyList",
  // list为父组件传过来的商品列表
  // isMore为是否显示“浏览更多”
  props: ["list", "isMore", "isDelete"],
  data() {
    return {};
  },
  computed: {
    // 通过list获取当前显示的商品的分类ID，用于“浏览更多”链接的参数
    categoryID: function () {
      let categoryID = [];
      if (this.list != "") {
        for (let i = 0; i < this.list.length; i++) {
          const id = this.list[i].category_id;
          if (!categoryID.includes(id)) {
            categoryID.push(id);
          }
        }
      }
      return categoryID;
    },
  },
  methods: {
    deleteCollect(product_id) {
      this.$axios
        .post("/api/user/collect/deleteCollect", {
          user_id: this.$store.getters.getUser.user_id,
          product_id: product_id,
        })
        .then((res) => {
          switch (res.data.code) {
            case "001":
              // 删除成功
              // 删除删除列表中的该商品信息
              for (let i = 0; i < this.list.length; i++) {
                const temp = this.list[i];
                if (temp.product_id == product_id) {
                  this.list.splice(i, 1);
                }
              }
              // 提示删除成功信息
              this.notifySucceed(res.data.msg);
              break;
            default:
              // 提示删除失败信息
              this.notifyError(res.data.msg);
          }
        })
        .catch((err) => {
          // return Promise.reject(err);
        });
    },
    jia(item) {
      console.log(item);
      this.$router.push({
        path: "/goods/details",
        query: {
          id: item.Id,
        },
      });
    },
    mouseenter(item,indexs) {
      this.list[indexs].tempIndex = item
      console.log(this.list[indexs].tempIndex);
      this.$forceUpdate()
    },
  },
};
</script>
<style scoped>
.myList ul li {
  z-index: 1;
  float: left;
  width: 236px;
  height: 406px;
  padding: 10px 0;
  margin: 0 0 14.5px 13.7px;
  background-color: white;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  position: relative;
}

.myList ul li:hover {
  z-index: 2;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
}

.myList ul li img {
  display: block;
  width: 180px;
  height: 180px;
  background: url(../assets/imgs/placeholder.png) no-repeat 50%;
  margin: 0 auto;
}

.myList ul li h2 {
  margin: 10px 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.myList ul li h3 {
  margin: 5px 10px;
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #b0b0b0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.myList ul li p {
  margin: 10px 10px 10px;
  text-align: center;
  color: #e23435;
}

.myList ul li p .del {
  margin-left: 0.5em;
  color: #b0b0b0;
  text-decoration: line-through;
}

.myList #more {
  text-align: center;
  line-height: 280px;
}

.myList #more a {
  font-size: 18px;
  color: #333;
}

.myList #more a:hover {
  color: #ff6700;
}

.myList ul li .delete {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}

.myList ul li:hover .delete {
  display: block;
}

.myList ul li .delete:hover {
  color: #ff6700;
}

.inp {
  width: 50px;
  height: 30px;
  border: 1px solid#f4f4f4;
  padding: 0 6px;
  margin: 0 6px;
}

.xs_slide {
  width: 236px;
  position: relative;
  margin-top: 10px;
  overflow: hidden;
}

.xs_slide .small-xs-shop {
  width: 236px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 34px;
  overflow: hidden;
}

.xs_slide .small-xs-shop ul li {
  float: left;
  width: 38px;
  height: 34px;
  text-align: left;
}

.xs_slide .small-xs-shop ul li a {
  border: 1px solid #ccc;
  padding: 1px;
  width: 30px;
  height: 30px;
  display: block;
}

.xs_slide .small-xs-shop ul li a img {
  width: 30px;
  height: 30px;
  display: block;
}

.xs_slide .small-xs-shop ul .bored a {
  padding: 0;
  border-width: 2px;
  border-color: #e23435;
}

.xs_slide .l_prev,
.xs_slide .l_next {
  display: block;
  position: absolute;
  top: 0;
  width: 20px;
  height: 34px;
  line-height: 34px;
  color: #858585;
  font-size: 16px;
  text-align: center;
  font-family: SimSun;
}

.xs_slide .l_prev {
  left: 0;
}

.xs_slide .l_next {
  right: 0;
}
</style>
